import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Star, ChevronLeft, ChevronRight } from 'react-feather'
import Slider from 'react-slick-preact-patch'

const ArrowContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 40%;
  height: 27px;
  width: 27px;
  background: #f6f6f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    left: -27px;
    svg {
      margin-left: -2px;
    }
  }
  &.right {
    right: -27px;
    svg {
      margin-left: 2px;
    }
  }
  svg {
    stroke-width: 3;
  }
`

const StarsContainer = styled.div`
  svg {
    fill: white;
    stroke: none;
    margin-right: 4px;
    border-radius: 3px;
    padding: 3px;
  }
  margin-bottom: 15px;
`
const ReviewContainer = styled.div`
  padding: 0 10px;
`
const ReviewTitle = styled.h4`
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 0.5em;
  line-height: 1.3;
`
const ReviewBody = styled.p`
  font-size: 0.88em;
  color: #777;
`
const Reviewer = styled.p`
  font-size: 0.88em;
  color: #444 !important;
  font-weight: 600;
`
const ReviewSource = styled.span`
  font-weight: normal;
  color: #444;
  padding-left: 5px;
`
const NextArrow = (props) => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <div onClick={onClick}>
      <ArrowContainer className="left">
        <ChevronLeft size={20} />
      </ArrowContainer>
    </div>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <div onClick={onClick}>
      <ArrowContainer className="right">
        <ChevronRight size={20} />
      </ArrowContainer>
    </div>
  )
}

const Stars = () => (
  <StarsContainer>
    <Star size={28} className="callout-bg-brand-primary" />
    <Star size={28} className="callout-bg-brand-primary" />
    <Star size={28} className="callout-bg-brand-primary" />
    <Star size={28} className="callout-bg-brand-primary" />
    <Star size={28} className="callout-bg-brand-primary" />
  </StarsContainer>
)

const ReviewSourceComponent = (props) => {
  let { source } = props
  if (source === 'homeadvisor') return <ReviewSource>Home Advisor</ReviewSource>
  if (source === 'yelp') return <ReviewSource>Yelp</ReviewSource>
  if (source === 'bbb') return <ReviewSource>BBB</ReviewSource>
  if (source === 'yellowpages') return <ReviewSource>Yellow Pages</ReviewSource>
  if (source === 'facebook') return <ReviewSource>Facebook</ReviewSource>
  if (source === 'google') return <ReviewSource>Google</ReviewSource>
  if (source === 'houzz') return <ReviewSource>Houzz</ReviewSource>
  else return <ReviewSource>{source}</ReviewSource>
}
const ReviewComponent = (props) => {
  let { title, body, reviewer, source } = props
  return (
    <ReviewContainer>
      <Stars />
      <ReviewTitle className="hidden">{title}</ReviewTitle>
      <ReviewBody>{body}</ReviewBody>
      <Reviewer>
        {reviewer}
        <ReviewSourceComponent source={source} />
      </Reviewer>
    </ReviewContainer>
  )
}
var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const ReviewsSlider = ({ limitReviewsDisplayToCategoryId }) => (
  <StaticQuery
    query={graphql`
      query SiteTestimonials {
        allContentfulTestimonial(limit: 100) {
          edges {
            node {
              id
              reviewTitle
              reviewer
              reviewBody {
                id
                reviewBody
              }
              category {
                id
              }
              source
            }
          }
        }
      }
    `}
    render={(data) => {
      let reviewLimitCategoryID = limitReviewsDisplayToCategoryId
      return (
        <>
          <CardGeneral title={'Our Reviews'}>
            <Slider {...settings}>
              {data.allContentfulTestimonial.edges.map((item, i) => {
                //if a reviewLimitCategoryID is being specified,
                //limit the display to only show reviews with the same category ID
                if (reviewLimitCategoryID) {
                  // see if ID exists in the testimonial categories list
                  if (
                    item.node.category &&
                    item.node.category.filter(
                      (cat) => cat.id === reviewLimitCategoryID
                    ).length > 0
                  ) {
                    //if so return the testimonial
                    return (
                      <ReviewComponent
                        key={i}
                        title={item.node.reviewTitle}
                        body={item.node.reviewBody.reviewBody}
                        reviewer={item.node.reviewer}
                        source={item.node.source}
                      />
                    )
                  }
                }
                //if no review ID to limit to, display all
                else {
                  return (
                    <ReviewComponent
                      key={i}
                      title={item.node.reviewTitle}
                      body={item.node.reviewBody.reviewBody}
                      reviewer={item.node.reviewer}
                      source={item.node.source}
                    />
                  )
                }
              })}
            </Slider>
          </CardGeneral>
        </>
      )
    }}
  />
)

export default ReviewsSlider
