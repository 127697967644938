import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from "./card-general"
import { Check } from 'react-feather';

const SidebarHeader = styled.h4`
margin-bottom:.5em;
`
const SidebarP = styled.p`
color:#444;
`

const ContactInfo = () => (
  <StaticQuery
    query={graphql`
      query ContactInfoQuery {
        site {
          siteMetadata {
            companyInfo {
              phone
              email
              addressLine1
              addressLine2
              city
              state
              zip
              license
            }
          }
        }
      } 
      
    `}
    render={data => {
      let {siteMetadata} = data.site;
      return (
        <>
        <CardGeneral title={"About Us"}>
         
        {siteMetadata.companyInfo.license ? <div><SidebarHeader>Licensed Contractor</SidebarHeader>
        <SidebarP>License #: {siteMetadata.companyInfo.license}</SidebarP></div>
          : null}
        
        <SidebarHeader>Contact Us</SidebarHeader>
        <SidebarP>{siteMetadata.companyInfo.phone}</SidebarP>
      
      


        </CardGeneral>
      </>
      )
     
    }}
  />
)

export default ContactInfo

