import React from 'react'

const sources = [
  {
    alt: 'Google 5 Stars',
    url: '/assets/logos/google.png',
    rating: '4.9/5',
  },
  {
    alt: 'BBB A+ Rating',
    url: '/assets/logos/bbb.png',
    rating: 'A+',
  },
  {
    alt: 'Facebook 5 Stars',
    url: '/assets/logos/fb.png',
    rating: '5/5',
  },
  {
    alt: 'Angies List 5 Stars',
    url: '/assets/logos/angies.png',
    rating: '5/5',
  },
  {
    alt: 'Porch 5 Stars',
    url: '/assets/logos/porch.png',
    rating: '5/5',
  },
  {
    alt: 'Yelp 5 Stars',
    url: '/assets/logos/yelp.png',
    rating: '5/5',
  },
]
export default class Reputation extends React.Component {
  render() {
    return (
      <div
        className={
          'reputation-card' +
          (this.props && this.props.largeScreen
            ? ' reputation-bar-full-width'
            : '')
        }
      >
        <div className="r-grid">
          {sources.map(({ alt, url, rating }) => (
            <div
              className={
                'r-item' + (this.props.largeScreen ? ' large-screen' : '')
              }
            >
              <img src={url} alt={alt} loading="lazy" />
              <div className="r-item-details">
                <h4>{rating}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
