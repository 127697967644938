import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from "./card-general"
import { Check } from 'react-feather';
import MadeInUSA from '../assets/icons/madeinusa'

const WhyUsUnorderedList = styled.ul`
      list-style-type: none;
      padding: 0;
      margin:0;
      position:relative;

      li {
        list-style-type: none;
        padding: 0;
        padding-left:25px;
        line-height: 1.4;
        position:relative;
        svg {
          border-radius:50%;
          padding-top:5px;
          stroke-width: 4px;
          stroke:#fff;
          margin-left:-35px;
          padding:4px;
          position:absolute;
          top:2px;
        }
        
      }
`
const MadeInTheUSALogo = styled.div`
margin-top:-10px;
margin-bottom:15px;`

const WhyUs = () => (
  <StaticQuery
    query={graphql`
      query WhyUsListQuery {
        site {
          siteMetadata {
            whyUs
          }
        }
      } 
      
    `}
    render={data => {
      return (
        <>
        <CardGeneral title={"Why Us"} className={"theme-primary"}>
        {/* <MadeInTheUSALogo>
       
       <MadeInUSA/>
        </MadeInTheUSALogo> */}
          <WhyUsUnorderedList>
            {data.site.siteMetadata.whyUs.map((item, index) =><li key={index}><Check size={22} className="bg-color-primary stroke-color-primary-lightest"/>{item}</li>)}
          </WhyUsUnorderedList>
        </CardGeneral>
      </>
      )
     
    }}
  />
)

export default WhyUs

